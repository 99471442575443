import { Injectable } from '@angular/core';

export const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
    providedIn: 'root',
})
export class TokenStorageService {
    constructor() {}
    signOut(): void {
        window.localStorage.clear();
    }
    public saveToken(token: string): void {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, token);
    }
    public getToken(): string | null {
        return window.localStorage.getItem(TOKEN_KEY);
    }
    public saveUser(user: any): void {
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
    public updateUser(userData: any): void {
        const currentUserData = this.getUser();
        const updatedUserdata = { ...currentUserData, ...userData };
        window.localStorage.setItem(USER_KEY, JSON.stringify(updatedUserdata));
    }

    public getUser(): any {
        const user = window.localStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(user);
        }
        return {};
    }

    public checkSessionAuth(): any {
        const user = this.getUser();
        const currentSecondsEpoch = Date.now() / 1000;
        if (currentSecondsEpoch > user?.expire) {
            this.logout();
        }
    }

    public logout(): any {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
    }
}
