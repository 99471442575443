import { Component, Input } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-notification-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    // add NgbModalConfig and NgbModal to the component providers
    providers: [NgbModalConfig, NgbModal],
})
export class NotificationModalComponent {
    @Input() title!: string;
    @Input() description!: string;

    constructor(config: NgbModalConfig, public activeModal: NgbActiveModal) {
        // customize default values of modals used by this component tree
        config.backdrop = 'static';
        config.keyboard = false;
    }
}
