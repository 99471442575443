import { Location } from '@angular/common';
import {
    Component,
    OnDestroy,
    OnInit,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
    @Input() isOpened!: boolean;
    @Output() toggleIsOpened: EventEmitter<any> = new EventEmitter();
    @Input() headerType: string = 'withLogin';

    @Input() isAuthenticated: boolean = false;
    authSubscription: any;
    isMenuOpen = false;

    constructor(
        public router: Router,
        public location: Location,
        private tokenService: TokenStorageService,
    ) {}

    ngOnInit(): void {
        console.log('App header loaded');
    }

    ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    get currentlyActive() {
        return this.router.url?.split('/')?.[1];
    }

    onToggleOpened() {
        this.toggleIsOpened.emit(!this.isOpened);
    }

    signOut() {
        this.tokenService.logout();
    }
}
