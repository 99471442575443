import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const API_URL = environment.AUTH_API_URL;
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private http: HttpClient) {}

    getAllWidgetsList() {
        return this.http
            .get(API_URL + `widgets`)
            .pipe(retry(0), catchError(this.handleError));
    }

    getUserWidgets() {
        return this.http
            .get(API_URL + `user/widgets`)
            .pipe(retry(0), catchError(this.handleError));
    }

    getWidgetData(widgetId: any, duration: string, year: number) {
        return this.http
            .get(
                API_URL +
                    `user/widget/${widgetId}?from=${year}-01-01&to=${year}-12-31&stat=${duration}`,
            )
            .pipe(retry(0), catchError(this.handleError));
    }

    postWidgetData(data: any): Observable<any> {
        return this.http.post(API_URL + 'user/widget', data, httpOptions);
    }

    getNextSteps() {
        return this.http
            .get(API_URL + `dashboard/next-steps`)
            .pipe(retry(0), catchError(this.handleError));
    }

    // Error handling
    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = error?.error?.message ?? `${error.message}`;
        }

        return throwError(() => {
            return errorMessage;
        });
    }
}
