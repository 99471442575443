<div class="app-notification-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
        <p>{{ description }}</p>
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <app-button
            (click)="handleClick()"
            kind="secondary"
            size="fit"
            [isDisabled]="isLoading"
        >
            Go to next step
        </app-button>
    </div>
</div>
