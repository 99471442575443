import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { TokenStorageService } from '../services/token-storage.service';
import { catchError, Observable, throwError, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModalComponent } from '../shared/components/modal/modal.component';
import { AssessmentErrorModalComponent } from '../shared/components/assessment-error-modal/assessment-error-modal.component';

const TOKEN_HEADER_KEY = 'X-AUTH-TOKEN';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private is401ErrorHandled = false;
    private errorSubject = new Subject<void>();

    constructor(
        private token: TokenStorageService,
        private modalService: NgbModal,
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        let authReq = req;
        const token = this.token.getToken();
        if (token != null) {
            authReq = req.clone({
                headers: req.headers.set(TOKEN_HEADER_KEY, token),
            });
        }
        return next.handle(authReq).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    debugger;
                    if (
                        (err.status === 417 &&
                            req.url.includes('mini_assessment')) ||
                        req.url.includes('comprehensive_assessment')
                    ) {                                            
                        const modalRef = this.modalService.open(
                            AssessmentErrorModalComponent,
                            {
                                size: 'xl',
                                centered: true,
                                scrollable: true,
                                backdrop: 'static',
                                keyboard: false,
                            },
                        );

                        modalRef.componentInstance.title = 'Error';
                        modalRef.componentInstance.description = 'You have already completed this assessment';
                    } else if (err.status === 401 && !this.is401ErrorHandled) {
                        this.is401ErrorHandled = true;

                        const modalRef = this.modalService.open(
                            NotificationModalComponent,
                            {
                                size: 'lg',
                                centered: true,
                                scrollable: true,
                            },
                        );
                        modalRef.componentInstance.title = 'Error';
                        modalRef.componentInstance.description =
                            'Your session has expired. Logging out.';

                        setTimeout(() => {
                            this.token.logout();
                        }, 3000);

                        this.errorSubject.next();
                    } else if (!req.url.includes('login')) {
                        let errorMessage = '';

                        if (err.error instanceof ErrorEvent) {
                            errorMessage = err.error.message;
                        } else {
                            errorMessage =
                                err?.error?.message ?? `${err.message}`;
                        }

                        const modalRef = this.modalService.open(
                            NotificationModalComponent,
                            {
                                size: 'xl',
                                centered: true,
                                scrollable: true,
                            },
                        );
                        modalRef.componentInstance.title = 'Error';
                        modalRef.componentInstance.description = errorMessage;
                    }
                }
                return throwError(() => err);
            }),
        );
    }

    on401ErrorHandled(): Observable<void> {
        return this.errorSubject.asObservable();
    }
}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
