import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { RootAuthGuard } from './helpers/root-auth.guard';

const routes: Routes = [
    { path: '', canActivate: [RootAuthGuard], children: [], pathMatch: 'full' },
    {
        path: 'policies',
        loadChildren: () =>
            import('./modules/tos/tos.module').then((m) => m.TosModule),
    },    
    {
        path: 'about-us',
        loadChildren: () =>
            import('./modules/about-us/aboutus.module').then(
                (m) => m.AboutusModule,
            ),
    },
    {
        path: 'landing',
        loadChildren: () =>
            import('./modules/landing/landing.module').then(
                (m) => m.LandingModule,
            ),
    },
    {
        path: 'assessment',
        loadChildren: () =>
            import('./modules/assessment/assessment.module').then(
                (m) => m.AssessmentModule,
            ),
    },
    {
        path: 'account',
        loadChildren: () =>
            import('./modules/account/account.module').then(
                (m) => m.AccountModule,
            ),
    },
    {
        path: 'health-stats',
        loadChildren: () =>
            import('./modules/health-stats/health-stats.module').then(
                (m) => m.HealthStatsModule,
            ),
    },
    {
        path: 'cart',
        loadChildren: () =>
            import('./modules/cart/cart.module').then((m) => m.CartModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./modules/dashboard/dashboard.module').then(
                (m) => m.DashboardModule,
            ),
    },
    {
        path: 'supplements',
        loadChildren: () =>
            import('./modules/supplements/supplements.module').then(
                (m) => m.SupplementsModule,
            ),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
