import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModalComponent } from '../shared/components/modal/modal.component';

const API_URL = environment.AUTH_API_URL;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient, private modalService: NgbModal) {}

    getPublicContent(): Observable<any> {
        return this.http.get(API_URL + `user`);
    }

    updateUserDetails(data: any): Observable<any> {
        return this.http.put(API_URL + 'user/update', data, httpOptions);
    }

    uploadUserPic(formData: FormData): Observable<any> {
        return this.http
            .post(API_URL + 'upload/user/picture', formData)
            .pipe(retry(0), catchError(this.handleError));
    }

    /** Resets all users data */
    deleteUserData(data: any): Observable<any> {
        return this.http
            .delete(API_URL + 'setting/reset-account', {
                body: data,
            })
            .pipe(retry(0), catchError(this.handleError));
    }

    getUserAddress(): Observable<any> {
        return this.http
            .get(API_URL + `user-address`)
            .pipe(retry(0), catchError(this.handleError));
    }

    addUserAddress(data: any): Observable<any> {
        if (data.address_id) {
            return this.http
                .put(API_URL + 'user-address', data, httpOptions)
                .pipe(retry(0), catchError(this.handleError));
        } else {
            return this.http
                .post(API_URL + 'user-address', data, httpOptions)
                .pipe(retry(0), catchError(this.handleError));
        }
    }

    handleError = (error: any) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = error?.error?.message ?? `${error.message}`;
        }
        
        return throwError(() => {
            return errorMessage;
        });
    };
}
