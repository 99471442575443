import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';
// import { LoadingService } from './core/services/loading.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isOpened: boolean = true;

    // isAuthenticated?: boolean = false;
    authSubscription!: Subscription;
    headerType: string = 'withLogin';

    constructor(
        private router: Router,
        private tokenService: TokenStorageService,
    ) {}

    ngOnInit(): void {
        this.tokenService.checkSessionAuth();
        this.router.events.subscribe((val) => {
            const currPage = window.location.pathname.split('/')[1];
            switch (currPage) {
                case 'assessment':
                case 'health-stats':
                case 'account':
                case 'cart':
                    this.headerType = 'onlyLogo';
                    break;
                case 'dashboard':
                    this.headerType = 'none';
                    break;
                default:
                    this.headerType = 'withLogin';
                    break;
            }
        });
    }

    get isAuthenticated() {
        return this.tokenService.getToken() !== null;
    }
}
