import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    NgbModalConfig,
    NgbModal,
    NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-assessment-error-modal',
    templateUrl: './assessment-error-modal.component.html',
    styleUrls: ['./assessment-error-modal.component.scss'],
    // add NgbModalConfig and NgbModal to the component providers
    providers: [NgbModalConfig, NgbModal],
})
export class AssessmentErrorModalComponent implements OnInit {
    @Input() title!: string;
    @Input() description!: string;

    nextStepsData: any = null;
    isLoading = false;
    personalInfo: any = {};

    constructor(
        config: NgbModalConfig,
        public activeModal: NgbActiveModal,
        private dashboardService: DashboardService,
        private router: Router,
        private user: UserService,
    ) {
        // customize default values of modals used by this component tree
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit(): void {
        this.user
            .getPublicContent()
            .pipe(take(1))
            .subscribe((res) => {
                const { data } = res;
                this.personalInfo = data;
                
                this.loadNextSteps();
            });
    }

    loadNextSteps() {
        this.isLoading = true;

        return this.dashboardService
            .getNextSteps()
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    const { data } = response as any;
                    this.nextStepsData = data;
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                },
            });
    }

    handleClick = () => {
        if (!this.nextStepsData?.user_info) {
            this.router.navigate(['/assessment/demographic-assessment']);
            this.activeModal.dismiss();
            return;
        }

        if (!this.nextStepsData?.mini_assessment) {
            this.activeModal.dismiss();
            this.router.navigate(['/assessment/mini-assessment']);
            return;
        }

        if (!this.nextStepsData?.subscribed) {
            const cartId = this.personalInfo?.cart_id;

            // VFI case
            if (!cartId) {
                this.activeModal.dismiss();
                this.router.navigate(['/assessment/vfi-info']);
                return;
            }

            const path = !this.nextStepsData?.main_assessment ? 'mini' : 'main';
            this.activeModal.dismiss();
            this.router.navigate([`/cart/subscription/${cartId}/${path}`]);
            return;
        }

        this.activeModal.dismiss();
        this.router.navigate([`/dashboard`]);
    };
}
