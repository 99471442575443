<div class="app-notification-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>

        <button
            type="button"
            class="btn-close btn-close-white"
            aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <p>{{ description }}</p>
        <ng-content></ng-content>
    </div>

</div>
