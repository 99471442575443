import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable({
    providedIn: 'root',
})
export class RootAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private tokenStorage: TokenStorageService,
    ) {}

    canActivate(): boolean {
        const isLoggedIn = !!this.tokenStorage.getToken();

        if (isLoggedIn) {
            // User is logged in, redirect to dashboard
            this.router.navigate(['/dashboard']);
            return false;
        } else {
            // User is not logged in, redirect to landing page
            this.router.navigate(['/landing']);
            return false;
        }
    }
}
