<ng-template #popContent>
    <div class="list-group list-group-flush">
        <a
            [routerLink]="'dashboard/settings'"
            class="list-group-item list-group-item-action"
        >
            Account
        </a>
        <a
            [routerLink]="'dashboard'"
            class="list-group-item list-group-item-action"
            >Dashboard</a
        >
        <a (click)="signOut()" class="list-group-item list-group-item-action"
            >Logout</a
        >
    </div>
</ng-template>

<header *ngIf="headerType === 'withLogin'" class="app-header d-none d-sm-flex">
    <img
        routerLink="/"
        src="assets/images/header-single-logo.svg"
        role="button"
        alt="logo"
        class="logo"
    />
    <nav class="header-nav">
        <ul>
            <li>
                <a
                    href="/landing"
                    [class.active-link]="currentlyActive === 'landing'"
                    >Home</a
                >
            </li>
            <li>
                <a
                    href="/about-us"
                    [class.active-link]="currentlyActive === 'about-us'"
                    >About us</a
                >
            </li>
            <li>
                <a
                    href="/supplements/packs"
                    [class.active-link]="currentlyActive === 'supplements'"
                    >Shop</a
                >
            </li>
        </ul>
    </nav>
    <div class="header-actions">
        <img
            *ngIf="isAuthenticated"
            src="assets/images/user.svg"
            alt="user"
            class="user"
            popoverClass="custom-popover"
            [ngbPopover]="popContent"
        />
        >
        <a
            *ngIf="!isAuthenticated"
            href="account/signin"
            class="header-action-link"
            >Sign in</a
        >

        <span
            class="text-white border-start border-white ps-3 ms-3 position-relative"
            ><i
                [routerLink]="'/cart/user'"
                role="button"
                class="bi bi-cart3"
                [class.active-link]="currentlyActive === 'cart'"
            ></i>
        </span>
    </div>
</header>
<header *ngIf="headerType === 'onlyLogo'" class="app-header single-logo-header">
    <img
        routerLink="/"
        role="button"
        src="assets/images/header-single-logo.svg"
        alt="logo"
    />
</header>

<!-- Responsive Header - mobile -->

<header
    *ngIf="headerType === 'withLogin'"
    class="app-header d-sm-none d-xs-flex"
    ngbDropdown
    placement="bottom"
>
    <img
        routerLink="/"
        src="assets/images/header-single-logo.svg"
        role="button"
        alt="logo"
        class="logo"
    />

    <button
        class="navbar-toggler shadow-none"
        type="button"
        (click)="isMenuOpen = !isMenuOpen"
        [attr.aria-expanded]="isMenuOpen"
        aria-label="Toggle navigation"
        ngbDropdownToggle
    >
        <i
            class="bi bi-list-nested text-white fs-3"
            [class.bi-x-lg]="isMenuOpen"
        ></i>
    </button>
    <div
        placement="bottom"
        class="collapse navbar-collapse fixed-top w-100 mobile-menu"
        [ngClass]="{ show: isMenuOpen }"
    >
        <ul class="navbar-nav pb-4" ngbDropdownMenu>
            <li ngbDropdownItem class="nav-item py-1">
                <a
                    class="nav-link text-white"
                    [class.active-link]="currentlyActive === 'landing'"
                    href="/landing"
                    >Home</a
                >
            </li>
            <li ngbDropdownItem class="nav-item py-1">
                <a
                    class="nav-link text-white"
                    [class.active-link]="currentlyActive === 'about-us'"
                    href="/about-us"
                    >About us</a
                >
            </li>
            <li ngbDropdownItem class="nav-item py-1">
                <a
                    class="nav-link text-white"
                    [class.active-link]="currentlyActive === 'supplements'"
                    href="/supplements/packs"
                    >Shop</a
                >
            </li>

            <li ngbDropdownItem class="nav-item py-1">
                <a
                    class="nav-link text-white"
                    href="/cart/user"
                    [class.active-link]="currentlyActive === 'cart'"
                    >Cart</a
                >
            </li>

            <!-- authenticated links -->
            <div class="divider"></div>
            <li *ngIf="!isAuthenticated" ngbDropdownItem class="nav-item py-1">
                <a class="nav-link text-white" href="/account/signin"
                    >Sign in</a
                >
            </li>

            <li *ngIf="isAuthenticated" ngbDropdownItem class="nav-item py-1">
                <a class="nav-link text-white" href="/dashboard/settings"
                    >Account</a
                >
            </li>
            <li *ngIf="isAuthenticated" ngbDropdownItem class="nav-item py-1">
                <a class="nav-link text-white" href="/dashboard">Dashboard</a>
            </li>

            <li
                (click)="signOut()"
                *ngIf="isAuthenticated"
                ngbDropdownItem
                class="nav-item py-1"
            >
                <a class="nav-link text-white" href="/account/signin">Logout</a>
            </li>
        </ul>
    </div>
</header>
