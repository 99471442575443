<button
    type="button"
    [disabled]="isDisabled || isLoading"
    (click)="onClick($event)"
    class="btn app-button"

    [class.sm]="size === buttonSizes.sm"
    [class.md]="size === buttonSizes.md"
    [class.lg]="size === buttonSizes.lg"
    [class.full-width]="size === buttonSizes.full"
    [class.fit-content]="size === buttonSizes.fit"
    [class.navigation]="size === buttonSizes.navigation"

    [class.primary]="kind === buttonKinds.primary"
    [class.secondary]="kind === buttonKinds.secondary"
    [class.transparent]="kind === buttonKinds.transparent"
    [class.text]="kind === buttonKinds.text"

    [type]="type"
>
    <ng-component *ngIf="isLoading; else content">
        <span
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
        ></span>
        {{ loadingText }}...
    </ng-component>

    <ng-template #content>
        <ng-content></ng-content>
    </ng-template>
</button>
