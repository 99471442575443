import { Component, EventEmitter, Input, Output } from '@angular/core';

enum BUTTON_KINDS {
    primary = 'primary',
    secondary = 'secondary',
    transparent = 'transparent',
    text = 'text',
}

enum BUTTON_SIZES {
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    full = 'full',
    fit = 'fit',
    navigation = 'navigation' // special size which is like md in general but full in small screens
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() kind: string = BUTTON_KINDS.primary;
    @Input() size: string = BUTTON_SIZES.md;
    @Input() type: string = '';
    @Input() isDisabled: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() loadingText: string = 'Saving';

    @Output() handleClick: EventEmitter<any> = new EventEmitter();

    buttonSizes = BUTTON_SIZES;
    buttonKinds = BUTTON_KINDS;

    onClick(event: MouseEvent) {
        this.handleClick.emit(event);
    }
}
